exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-sustainability-index-js": () => import("./../../../src/pages/sustainability/index.js" /* webpackChunkName: "component---src-pages-sustainability-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-us-page-js": () => import("./../../../src/templates/contact-us-page.js" /* webpackChunkName: "component---src-templates-contact-us-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-jobs-job-details-js": () => import("./../../../src/templates/jobs/job-details.js" /* webpackChunkName: "component---src-templates-jobs-job-details-js" */),
  "component---src-templates-news-news-post-js": () => import("./../../../src/templates/news/news-post.js" /* webpackChunkName: "component---src-templates-news-news-post-js" */),
  "component---src-templates-products-post-harvest-page-js": () => import("./../../../src/templates/products/post-harvest-page.js" /* webpackChunkName: "component---src-templates-products-post-harvest-page-js" */),
  "component---src-templates-products-pre-harvest-page-js": () => import("./../../../src/templates/products/pre-harvest-page.js" /* webpackChunkName: "component---src-templates-products-pre-harvest-page-js" */),
  "component---src-templates-recruitment-page-js": () => import("./../../../src/templates/recruitment-page.js" /* webpackChunkName: "component---src-templates-recruitment-page-js" */),
  "component---src-templates-sustainability-family-page-js": () => import("./../../../src/templates/sustainability/family-page.js" /* webpackChunkName: "component---src-templates-sustainability-family-page-js" */),
  "component---src-templates-sustainability-overview-page-js": () => import("./../../../src/templates/sustainability/overview-page.js" /* webpackChunkName: "component---src-templates-sustainability-overview-page-js" */),
  "component---src-templates-sustainability-practices-page-js": () => import("./../../../src/templates/sustainability/practices-page.js" /* webpackChunkName: "component---src-templates-sustainability-practices-page-js" */),
  "component---src-templates-sustainability-workforce-page-js": () => import("./../../../src/templates/sustainability/workforce-page.js" /* webpackChunkName: "component---src-templates-sustainability-workforce-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

