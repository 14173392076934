const {camelCase} =  require('lodash');
let onChangeTimeout = null;

function stripSlashes(path) {
    if (path === null) {
        return '';
    }

    return camelCase(path.replace('/', ''));
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
    clearTimeout(onChangeTimeout);

    onChangeTimeout = setTimeout( () => {
        const prevPathClass = stripSlashes(prevLocation ? prevLocation.pathname : null);
        let nextPathClass = '';

        if (location.pathname === '/') {
            nextPathClass = 'home';
        } else {
            nextPathClass = stripSlashes(location.pathname);
        }

        if (prevPathClass) {
            document.body.classList.remove(prevPathClass, 'home');
        } else {
            document.body.classList.remove('home');
        }

        document.body.classList.add(nextPathClass);
    }, 600);
};
